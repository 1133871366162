import React, {useContext} from "react";
import {ThemeDataContext} from '../layout';

const subjectAndBody = `?subject=I am interested in working with you&body=Greetings, my name is `;

const HireMe = ({rotate = true}) => {
    const themeData = useContext(ThemeDataContext);
    const email = themeData.wordpressAcfOptions.options.email;


    return (
        <a href={`mailto:${email}${subjectAndBody}`}>
            <div className={`hire-me-cta ${rotate ? 'rotate' : ''}`}>
                <HireMeText/>
                <div className="hire-me-cta--wave">
                    👋
                </div>
            </div>
        </a>
    )
}


const HireMeText = () => (
    <svg 
        width="130" 
        height="130" 
        viewBox="0 0 130 130" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        className="hire-me-cta--text"
    >
        <path d="M52.0446 14.1032L50.3128 14.635L49.4806 12.6651L48.611 12.9273L49.0458 15.0169L47.2765 15.5562L45.0273 3.16063L47.0141 2.55396L52.0446 14.1032ZM48.8659 11.2196L47.6738 8.39598L48.3036 11.3919L48.8659 11.2196Z" fill="black"/>
        <path d="M58.0348 0.344482L58.1997 12.9797L56.258 13.2643L53.0117 1.08597L55.1334 0.7714L56.4604 5.80453L56.288 0.599135L58.0348 0.344482Z" fill="black"/>
        <path d="M67.7291 12.5753L65.9148 12.5454L65.7349 10.4183L64.8277 10.4033L64.5953 12.5229L62.751 12.4929L64.4378 0L66.5146 0.0299591L67.7291 12.5753ZM65.5999 8.85291L65.3375 5.79708L65.0076 8.83793L65.5999 8.85291Z" fill="black"/>
        <path d="M73.1866 13.1445L71.1924 12.8749L72.8717 0.464355L74.8659 0.733987L73.1866 13.1445Z" fill="black"/>
        <path d="M81.5684 15.0994L77.1826 13.8936L80.5113 1.82007L82.4531 2.35184L79.6567 12.4855L82.1082 13.1596L81.5684 15.0994Z" fill="black"/>
        <path d="M88.8108 18.1103L87.154 17.3688L87.8287 15.3391L87.004 14.9721L85.9544 16.8295L84.2676 16.0731L90.7451 5.25037L92.6419 6.09671L88.8108 18.1103ZM88.316 13.8411L89.2757 10.9276L87.7762 13.594L88.316 13.8411Z" fill="black"/>
        <path d="M97.1172 20.6418C96.7948 21.1361 96.3375 21.4507 95.7377 21.5706C95.138 21.6829 94.6132 21.5855 94.1484 21.2859L91.8018 19.758L98.6541 9.27234L100.978 10.7853C101.428 11.0774 101.705 11.5193 101.818 12.096C101.938 12.6727 101.84 13.212 101.51 13.7063L100.311 15.5413C100.116 15.8484 99.8311 16.0656 99.4638 16.2079C99.1039 16.3577 98.7666 16.3876 98.4592 16.2978C99.0365 16.9494 99.0964 17.616 98.6541 18.29L97.1172 20.6418ZM96.7873 17.4961C97.1247 16.9793 97.1397 16.6273 96.8398 16.4251L96.4649 16.1854L94.4482 19.2787L94.7631 19.4884C95.1005 19.7131 95.4079 19.6157 95.6778 19.2038L96.7873 17.4961ZM99.6662 13.0322C99.9436 12.6128 99.9211 12.2907 99.5987 12.081L99.2839 11.8713L97.5445 14.5377L97.9269 14.7698C98.2118 14.9571 98.4892 14.8447 98.7591 14.4253L99.6662 13.0322Z" fill="black"/>
        <path d="M102.254 27.832L98.8799 24.7762L107.292 15.4963L108.784 16.8445L101.721 24.6339L103.603 26.334L102.254 27.832Z" fill="black"/>
        <path d="M107.344 33.7265L104.465 30.2063L114.181 22.2821L117.06 25.8023L115.568 27.0231L113.964 25.0608L111.317 27.2179L112.329 28.4537L110.897 29.6221L109.885 28.3863L107.239 30.5433L108.843 32.5057L107.344 33.7265Z" fill="black"/>
        <path d="M124.182 42.5718L123.185 40.2425L120.044 41.5832L120.674 43.0512L118.972 43.7777L118.342 42.3097L113.431 44.4143L112.637 42.5644L124.16 37.6361L125.952 41.8154L124.182 42.5718Z" fill="black"/>
        <path d="M118.919 53.8964C118.154 54.0912 117.472 53.9863 116.88 53.5819C116.355 53.2149 116.01 52.7056 115.838 52.0465C115.665 51.3874 115.725 50.7732 116.01 50.1965C116.347 49.5673 116.895 49.1554 117.652 48.9607L125.261 47.0208C126.041 46.8261 126.723 46.9309 127.301 47.3504C127.825 47.7174 128.163 48.2417 128.32 48.9307C128.47 49.5973 128.403 50.204 128.118 50.7507C127.795 51.3649 127.263 51.7693 126.521 51.9566L118.919 53.8964ZM126.153 49.9044C126.453 49.8295 126.558 49.6348 126.483 49.3277C126.401 49.0206 126.213 48.9008 125.921 48.9757L118.012 50.9979C117.704 51.0803 117.584 51.2675 117.667 51.5821C117.749 51.8892 117.937 52.009 118.252 51.9266L126.153 49.9044Z" fill="black"/>
        <path d="M117.555 61.5359C117.825 61.3487 118.252 61.2288 118.83 61.1764L122.083 60.8993C122.803 60.8394 123.148 60.5922 123.111 60.1653L123.066 59.671L117.413 60.1503L117.24 58.1505L129.73 57.0945L129.978 60.0305C130.03 60.6072 129.858 61.124 129.483 61.5809C129.116 62.0452 128.621 62.2999 128.006 62.3523L125.39 62.5695C124.827 62.6145 124.318 62.4347 123.853 62.0228C123.553 62.547 122.976 62.8391 122.106 62.914L119.002 63.1762C118.402 63.2286 117.96 63.3484 117.69 63.5357L117.555 61.5359ZM127.406 60.39C127.909 60.345 128.141 60.1204 128.111 59.7009L128.074 59.259L125 59.5212L125.045 60.0305C125.075 60.405 125.337 60.5697 125.832 60.5248L127.406 60.39Z" fill="black"/>
        <path d="M126.513 80.0283L127.016 77.5416L123.672 76.8675L123.357 78.4329L121.543 78.0734L121.858 76.508L116.617 75.4595L117.015 73.4896L129.302 75.9538L128.403 80.4102L126.513 80.0283Z" fill="black"/>
        <path d="M113.986 84.4022C114.308 84.3498 114.75 84.4247 115.29 84.6269L118.349 85.7729C119.031 86.0275 119.444 85.9526 119.594 85.5557L119.766 85.0913L114.451 83.099L115.155 81.2191L126.888 85.6156L125.854 88.3793C125.651 88.926 125.276 89.3155 124.737 89.5627C124.204 89.8173 123.649 89.8398 123.072 89.6226L120.613 88.7013C120.088 88.5066 119.706 88.1171 119.459 87.5479C118.964 87.8925 118.312 87.9074 117.502 87.6078L114.585 86.5143C114.016 86.3046 113.573 86.2222 113.251 86.2747L113.986 84.4022ZM123.38 87.5929C123.852 87.7726 124.167 87.6603 124.309 87.2708L124.467 86.8514L121.58 85.7654L121.4 86.2447C121.265 86.5967 121.438 86.8589 121.895 87.0311L123.38 87.5929Z" fill="black"/>
        <path d="M109.646 93.0081L111.895 89.0535L122.795 95.24L120.546 99.1946L118.867 98.2434L120.119 96.0339L117.15 94.3487L116.363 95.7418L114.759 94.8281L115.546 93.435L112.577 91.7498L111.325 93.9593L109.646 93.0081Z" fill="black"/>
        <path d="M104.683 99.6589L107.524 96.1088L117.315 103.928L114.474 107.478L112.967 106.272L114.549 104.288L111.88 102.161L110.883 103.411L109.436 102.258L110.433 101.007L107.764 98.88L106.182 100.865L104.683 99.6589Z" fill="black"/>
        <path d="M98.9014 105.321L102.245 102.228L110.762 111.418L109.285 112.781L102.133 105.067L100.266 106.789L98.9014 105.321Z" fill="black"/>
        <path d="M92.5371 109.897L94.0365 108.879L95.4085 110.519L96.1582 110.01L95.1236 108.137L96.653 107.104L102.471 118.293L100.754 119.462L92.5371 109.897ZM96.4281 111.717L98.3998 114.062L96.9154 111.38L96.4281 111.717Z" fill="black"/>
        <path d="M84.4023 113.972L86.0817 113.2L90.9548 118.743L88.001 112.317L89.5903 111.59L94.8233 122.967L92.994 123.806L88.9381 119.409L91.3147 124.577L89.6353 125.349L84.4023 113.972Z" fill="black"/>
        <path d="M76.5683 118.743C76.3659 117.979 76.4708 117.297 76.8682 116.706C77.228 116.181 77.7378 115.829 78.3976 115.657C79.0573 115.485 79.6721 115.537 80.2493 115.822C80.8791 116.159 81.2989 116.698 81.4938 117.455L83.4806 125.042C83.683 125.821 83.578 126.495 83.1657 127.087C82.8058 127.611 82.281 127.955 81.5913 128.113C80.9241 128.262 80.3168 128.203 79.7695 127.925C79.1548 127.611 78.7499 127.079 78.555 126.338L78.0527 124.428L80.0619 123.903L80.6017 125.963C80.6767 126.263 80.8716 126.368 81.179 126.285C81.4863 126.203 81.6063 126.015 81.5238 125.716L79.4547 117.822C79.3722 117.515 79.1773 117.402 78.8699 117.477C78.5625 117.56 78.45 117.754 78.525 118.061L79.0573 120.099L77.0481 120.623L76.5683 118.743Z" fill="black"/>
        <path d="M67.751 117.529L72.2792 117.095L73.4713 129.558L68.943 129.992L68.7631 128.075L71.2896 127.835L70.9672 124.443L69.3779 124.592L69.2054 122.757L70.7948 122.608L70.4724 119.215L67.9459 119.454L67.751 117.529Z" fill="black"/>
        <path d="M40.4541 118.526L40.8215 117.29L56.6254 122.008L56.258 123.244L40.4541 118.526Z" fill="black"/>
        <path d="M28.5645 106.055C29.0668 105.441 29.674 105.119 30.3863 105.089C31.0235 105.067 31.6083 105.276 32.1406 105.703C32.6729 106.13 32.9878 106.662 33.1002 107.291C33.2052 107.995 33.0103 108.654 32.5229 109.261L27.5748 115.35C27.0725 115.972 26.4578 116.294 25.7381 116.301C25.1008 116.324 24.516 116.099 23.9837 115.642C23.4664 115.193 23.159 114.668 23.0541 114.062C22.9416 113.38 23.1291 112.736 23.6164 112.137L24.8609 110.602L26.4728 111.912L25.1308 113.56C24.9359 113.8 24.9659 114.017 25.2133 114.219C25.4607 114.421 25.6856 114.399 25.873 114.159L31.016 107.823C31.2184 107.576 31.196 107.351 30.9486 107.149C30.7011 106.947 30.4762 106.969 30.2738 107.216L28.9468 108.856L27.3349 107.546L28.5645 106.055Z" fill="black"/>
        <path d="M22.3792 99.584C22.9715 99.0597 23.6237 98.8425 24.3359 98.9249C24.9732 99.0073 25.513 99.3069 25.9628 99.8162C26.4126 100.326 26.6451 100.902 26.66 101.539C26.6526 102.25 26.3527 102.872 25.7679 103.389L19.9052 108.602C19.3054 109.134 18.6531 109.351 17.9409 109.246C17.3037 109.164 16.7639 108.849 16.314 108.31C15.8792 107.785 15.6543 107.216 15.6543 106.602C15.6543 105.913 15.9467 105.306 16.5165 104.797L22.3792 99.584ZM17.7835 106.445C17.5511 106.647 17.5436 106.872 17.7535 107.111C17.9634 107.351 18.1883 107.366 18.4132 107.164L24.5159 101.741C24.7558 101.531 24.7708 101.307 24.5534 101.067C24.3434 100.827 24.1185 100.812 23.8786 101.03L17.7835 106.445Z" fill="black"/>
        <path d="M18.8783 90.2218L19.8529 91.7872L14.9498 97.2996L20.9625 93.5772L21.8847 95.0602L11.2313 101.651L10.1742 99.9435L14.0427 95.3823L9.20705 98.3782L8.23242 96.8128L18.8783 90.2218Z" fill="black"/>
        <path d="M5.60787 86.4919L6.18515 87.9074L16.0214 83.9004L16.7786 85.7653L6.94236 89.7724L7.51964 91.1879L5.75032 91.907L3.83105 87.2034L5.60787 86.4919Z" fill="black"/>
        <path d="M13.4044 74.9277L13.8242 76.6878L11.8075 77.3919L12.0174 78.2757L14.1316 77.9686L14.5664 79.7661L2.03126 81.2491L1.54395 79.2269L13.4044 74.9277ZM10.3306 77.9162L7.43668 78.9348L10.4655 78.4929L10.3306 77.9162Z" fill="black"/>
        <path d="M10.1659 66.8912C10.9531 66.8313 11.6053 67.0485 12.1226 67.5428C12.58 67.9922 12.8349 68.5539 12.8873 69.2355C12.9398 69.9171 12.7824 70.5087 12.4 71.0255C11.9577 71.5873 11.3504 71.9018 10.5707 71.9617L2.74375 72.5834C1.94156 72.6433 1.28931 72.4261 0.787002 71.9093C0.329678 71.4599 0.0897705 70.8832 0.0522849 70.1792C0.0222965 69.4976 0.187233 68.9134 0.554592 68.4191C0.97443 67.8649 1.5667 67.5653 2.33141 67.4979L4.30315 67.3406L4.46809 69.4077L2.3464 69.5725C2.03902 69.595 1.89658 69.7673 1.92656 70.0818C1.94906 70.3964 2.12149 70.5462 2.42137 70.5237L10.5632 69.8796C10.8781 69.8571 11.0281 69.6849 11.0056 69.3628C10.9831 69.0482 10.8106 68.8984 10.4883 68.9209L8.38158 69.0857L8.21664 67.0185L10.1659 66.8912Z" fill="black"/>
        <path d="M2.29412 57.6637L2.17416 59.1916L12.7676 59.993L12.6176 62.0003L2.02422 61.1914L1.90427 62.7193L0 62.577L0.382353 57.5139L2.29412 57.6637Z" fill="black"/>
        <path d="M17.4976 42.497L16.7554 44.3619L9.18334 41.351L16.3356 45.403L16.0732 46.0621L8.02129 44.1223L15.6384 47.1481L14.9936 48.7734L3.35059 44.1447L4.47515 41.3136L8.05878 42.2948L4.8725 40.3174L5.84713 37.8683L17.4976 42.497Z" fill="black"/>
        <path d="M21.7943 35.187L19.3803 39.0442L8.75684 32.4008L11.1709 28.5436L12.8053 29.5697L11.4558 31.7192L14.3497 33.5318L15.1969 32.1761L16.7638 33.1573L15.9166 34.5129L18.8105 36.3255L20.16 34.1759L21.7943 35.187Z" fill="black"/>
        <path d="M37.6281 12.9498L38.3853 13.9909L25.018 23.6527L24.2607 22.6116L37.6281 12.9498Z" fill="black"/>
    </svg>
);

export default HireMe;